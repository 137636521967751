.p-15 {
  padding: rem(15);
}

.py-32 {
  padding-top: rem(32);
  padding-bottom: rem(32);
}
.py-36 {
  padding-top: rem(36);
  padding-bottom: rem(36);
}
.py-48 {
  padding-top: rem(48);
  padding-bottom: rem(48);
}
.py-56 {
  padding-top: rem(56);
  padding-bottom: rem(56);
}
.py-64 {
  padding-top: rem(64);
  padding-bottom: rem(64);
}
.py-128 {
  padding-top: rem(128);
  padding-bottom: rem(128);
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-16 {
  padding-left: rem(16);
  padding-right: rem(16);
}
.px-24 {
  padding-left: rem(24);
  padding-right: rem(24);
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-8 {
  padding-top: rem(8);
}
.pt-16 {
  padding-top: rem(16);
}
.pt-32 {
  padding-top: rem(32);
}
.pt-38 {
  padding-top: rem(38);
}
.pt-50 {
  padding-top: rem(50);
}
.pt-52 {
  padding-top: rem(52);
}
.pt-56 {
  padding-top: rem(56) !important;
}
.pt-64 {
  padding-top: rem(64);
}
.pt-80 {
  padding-top: rem(80);
}
.pt-96 {
  padding-top: rem(96);
}

.pr-16 {
  padding-right: rem(16);
}

.pb-0 {
  padding-bottom: 0;
}
.pb-24 {
  padding-bottom: rem(24);
}
.pb-32 {
  padding-bottom: rem(32);
}
.pb-38 {
  padding-bottom: rem(38);
}
.pb-48 {
  padding-bottom: rem(48);
}
.pb-52 {
  padding-bottom: rem(52);
}
.pb-56 {
  padding-bottom: rem(56);
}
.pb-64 {
  padding-bottom: rem(64) !important;
}
.pb-72 {
  padding-bottom: rem(72);
}
.pb-84 {
  padding-bottom: rem(84);
}

.pl-0 {
  padding-left: 0 !important;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5p5 {
  margin-top: rem(5.5);
  margin-bottom: rem(5.5);
}

.mt-18 {
  margin-top: rem(18);
}
.mt-24 {
  margin-top: rem(24);
}
.mt-32 {
  margin-top: rem(32);
}

.mr-11 {
  margin-right: rem(11);
}
.mr-24 {
  margin-right: rem(24);
}
.mr-30 {
  margin-right: rem(30);
}
.mr-32 {
  margin-right: rem(32);
}
.mr-34 {
  margin-right: rem(34);
}

.mb-0 {
  margin-bottom: 0;
}
.mb-8 {
  margin-bottom: rem(8);
} // .5
.mb-14 {
  margin-bottom: rem(14);
}
.mb-16 {
  margin-bottom: rem(16);
} // 1
.mb-18 {
  margin-bottom: rem(18);
}
.mb-20 {
  margin-bottom: rem(20);
}
.mb-22 {
  margin-bottom: rem(22);
}
.mb-24 {
  margin-bottom: rem(24);
}
.mb-28 {
  margin-bottom: rem(28);
}
.mb-30 {
  margin-bottom: rem(30);
}
.mb-32 {
  margin-bottom: rem(32);
}
.mb-35 {
  margin-bottom: rem(35);
}
.mb-38 {
  margin-bottom: rem(38);
}
.mb-40 {
  margin-bottom: rem(40);
} // 2.5
.mb-44 {
  margin-bottom: rem(44);
}
.mb-48 {
  margin-bottom: rem(48);
}
.mb-56 {
  margin-bottom: rem(56);
}
.mb-60 {
  margin-bottom: rem(60);
}
.mb-64 {
  margin-bottom: rem(64);
}
.mb-72 {
  margin-bottom: rem(72);
}
.mb-86 {
  margin-bottom: rem(86);
}
.mb-96 {
  margin-bottom: rem(96);
}
.mb-112 {
  margin-bottom: rem(112);
}

@include media-breakpoint-up(md) {
  // 768
  .mx-md-n30 {
    margin-left: rem(-30);
    margin-right: rem(-30);
  }

  .mt-md-18 {
    margin-top: rem(18);
  }
  .mt-md-32 {
    margin-top: rem(32);
  }

  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-16 {
    margin-bottom: rem(16);
  }
  .mb-md-22 {
    margin-bottom: rem(22);
  }
  .mb-md-28 {
    margin-bottom: rem(28);
  }
  .mb-md-32 {
    margin-bottom: rem(32);
  }
  .mb-md-35 {
    margin-bottom: rem(35);
  }
  .mb-md-38 {
    margin-bottom: rem(38);
  }
  .mb-md-48 {
    margin-bottom: rem(48);
  }
  .mb-md-64 {
    margin-bottom: rem(64);
  }
  .mb-md-76 {
    margin-bottom: rem(76);
  }
  .mb-md-80 {
    margin-bottom: rem(80);
  }
  .mb-md-106 {
    margin-bottom: rem(106);
  }
  .mb-md-132 {
    margin-bottom: rem(132);
  }

  .py-md-24 {
    padding-top: rem(24);
    padding-bottom: rem(24);
  }
  .py-md-64 {
    padding-top: rem(64);
    padding-bottom: rem(64);
  }

  .px-md-30 {
    padding-left: rem(30);
    padding-right: rem(30);
  }

  .pt-md-112 {
    padding-top: rem(112);
  }
  .pt-md-128 {
    padding-top: rem(128);
  }

  .pb-md-64 {
    padding-bottom: rem(64);
  }
}

@include media-breakpoint-up(lg) {
  // 992

  .mt-lg-24 {
    margin-top: rem(24);
  }
  .mt-lg-112 {
    margin-top: rem(112);
  }

  .mb-lg-16 {
    margin-bottom: rem(16);
  }
  .mb-lg-48 {
    margin-bottom: rem(48);
  }
  m .mb-lg-64 {
    margin-bottom: rem(64);
  }
  .mb-lg-96 {
    margin-bottom: rem(96);
  }

  .p-lg-24 {
    padding: rem(24);
  }

  .py-lg-40 {
    padding-top: rem(40);
    padding-bottom: rem(40);
  }
  .py-lg-48 {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }
  .py-lg-96 {
    padding-top: rem(96);
    padding-bottom: rem(96);
  }
  .py-lg-128 {
    padding-top: rem(128);
    padding-bottom: rem(128);
  }

  .px-xl-96 {
    padding-left: rem(96);
    padding-right: rem(96);
  }

  .pt-lg-48 {
    padding-top: rem(48);
  }
  .pt-lg-72 {
    padding-top: rem(72);
  }
  .pt-lg-80 {
    padding-top: rem(80);
  }
  .pt-lg-112 {
    padding-top: rem(112);
  }
  .pt-lg-128 {
    padding-top: rem(128);
  }

  .pb-lg-32 {
    padding-bottom: rem(32);
  }
  .pb-lg-96 {
    padding-bottom: rem(96);
  }
  .pb-lg-112 {
    padding-bottom: rem(112) !important;
  } // 7
  .pb-lg-128 {
    padding-bottom: rem(128) !important;
  } // 8

  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-14 {
    margin-bottom: rem(14);
  }
  .mb-lg-38 {
    margin-bottom: rem(38);
  }
  .mb-lg-60 {
    margin-bottom: rem(60);
  }
  .mb-lg-80 {
    margin-bottom: rem(80);
  }

  .pb-lg-52 {
    padding-bottom: rem(52);
  }
  .pb-lg-80 {
    padding-bottom: rem(80);
  }
  .pb-lg-96 {
    padding-bottom: rem(96);
  }
}

@include media-breakpoint-up(xl) {
  // 1200
  .mx-xl-n48 {
    margin-left: rem(-48);
    margin-right: rem(-48);
  }

  .mr-xl-14 {
    margin-right: rem(14);
  }

  .mb-xl-56 {
    margin-bottom: rem(56);
  }
  .mb-xl-72 {
    margin-bottom: rem(72);
  }

  .py-xl-128 {
    padding-top: rem(128);
    padding-bottom: rem(128);
  }

  .pt-xl-32 {
    padding-top: rem(32);
  }
  .pt-xl-96 {
    padding-top: rem(96);
  }
  .pt-xl-106 {
    padding-top: rem(106);
  }

  .pb-xl-54 {
    padding-bottom: rem(54);
  }

  .pb-xl-112 {
    padding-bottom: rem(112);
  }

  .px-xl-48 {
    padding-left: rem(48);
    padding-right: rem(48);
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-16 {
    margin-bottom: rem(16);
  }
  .mb-xl-28 {
    margin-bottom: rem(28);
  }
  .mb-xl-40 {
    margin-bottom: rem(40);
  }
  .mb-xl-48 {
    margin-bottom: rem(48);
  }
  .mb-xl-64 {
    margin-bottom: rem(64);
  }
  .mb-xl-80 {
    margin-bottom: rem(80);
  }
  .mb-xl-96 {
    margin-bottom: rem(96);
  }
  .mb-xl-128 {
    margin-bottom: rem(128);
  }
}

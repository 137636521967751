.masthead--home__title {
    position: absolute;
    pointer-events: none;
    top: calc(50% - #{rem(24)});
    left: 0;
    right: 0;
    z-index: 2;
    @include media-breakpoint-up(md) {
        top: calc(50% - #{rem(60)});
    }
    @media (max-width: 767px) and (max-device-height: 411px) { 
        visibility: hidden;
    }
    @media (min-width: 768px) and (max-device-height: 521px) { 
        visibility: hidden;
    }
}

.masthead--home__scroll-down {
    bottom: rem(44);
    position: absolute;
    right: rem(82);
    z-index: 1;
}

.masthead--home__scroll-up {
    margin-top: rem(-24.5);
    position: fixed;
    right: rem(82);
    top: 50%;
    z-index: 2;
}

.swiper-home {
  .swiper-slide {
    padding-bottom: rem(54);
    &:nth-child(1) img { transform-origin: 0 100%; }
    &:nth-child(2) img { transform-origin: 100% 100%; }
    &:nth-child(3) img { transform-origin: 100% 0 }
    &:nth-child(4) img { transform-origin: 0 100%; }
    &:nth-child(5) img { transform-origin: 100% 100%; }
    &:nth-child(6) img { transform-origin: 100% 0 }
    &:nth-child(7) img { transform-origin: 0 100%; }
    &:nth-child(8) img { transform-origin: 100% 100%; }
    &:nth-child(9) img { transform-origin: 100% 0 }
    &:nth-child(10) img { transform-origin: 0 100%; }
  }
  img {
    transition: 10s ease-out;
    transform: scale(1.2);
  }
  .swiper-slide {
    &.swiper-slide-active {
      img {
        transform: scale(1);
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .swiper-slide {
      padding-bottom: rem(96)
    }
  }
}

/* .swiper-home {
  .swiper-slide {
    &.swiper-slide-visible{
      img {
        animation-name: sliderAmpliation;
        animation-duration: 7s;
        animation-timing-function: ease-in-out;
      }
    }
  }

}

@keyframes sliderAmpliation {
  0% {transform: scale(1)}
  100% {transform: scale(1.5)}
} */
.list-news {
	time {
    border-bottom: rem(1) solid $urbemar;
		line-height: 1.2;
	}
	li {
		&:not(:last-child) {
			margin-bottom: rem(32)
		}
	}
	@include media-breakpoint-up(md) {
		li {
			position: relative;
			&:not(:last-child) {
				margin-bottom: rem(112)
			}
		}
		&__maincol {
			height: 100%;
		}
		&__time {
			position:absolute;
			left:0;
			top: 0;
		}
		time {
			padding-bottom: rem(10)
		}
	}
}

.time--new {
	border-bottom: rem(1) solid $urbemar;
	padding-bottom: rem(24)
}

.nav-pagination {
	.disabled {
		pointer-events: none;
		cursor: default;
		opacity: .5;
	}
}
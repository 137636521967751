.scroll-indicator {
  opacity: 0;
  transition: opacity 500ms ease-out;
  span {
    font-size: 14px;
    margin-right: 10px;
  }
  &.active {
    opacity: 1;
  }
}



.icon-scroll-table {
  display: block;
  width: 48px;
  margin-bottom: 5px;
  .arrow {
    fill:#ff6b00;
  }
  #arrow-l {
    animation: bounce-left 1s infinite alternate;
  }
  #arrow-r {
    animation: bounce-right 1s infinite alternate;
  }
}
@keyframes bounce-right {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-5px);
  }
}
@keyframes bounce-left {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(5px);
  }
}

.form-control--transparent {
  background-color: transparent;
  border-bottom-color: #fff;
  color: #fff;
  &:focus {
    background-color: transparent;
    color: #fff;
  }
  &::placeholder {
    color: #fff;
  }
}

.validation-form {
  .invalid-feedback {
    display: block;
  }
}

.form-check {
  min-height: rem(22);
  label {
    padding-top: rem(2);
  }
}

.form-check-input {
  appearance: none;
  background-color: $white;
  border: 1px solid #12263a;
  cursor: pointer;
  height: rem(22);
  transition: all 0.15s ease-in-out;
  width: rem(22);
  &:checked {
    & + .form-check-label {
    }
  }
  &:focus {
    //box-shadow: $input-focus-box-shadow;
  }
}

[type="checkbox"] {
  &.form-check-input {
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem(14);
    &:checked {
      background-image: str-replace(
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.414 11.121'%3E%3Cpath fill='#{$urbemar}' d='M4.707 8.293 1.414 5 0 6.414l4.707 4.707 9.707-9.707L13 0Z'/%3E%3C/svg%3E"),
        "#",
        "%23"
      );
    }
  }
}

.form-check-input {
  &[type="radio"] {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    &:checked {
      background-image: str-replace(
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Ccircle fill='#{$urbemar}' cx='6' cy='6' r='6'/%3E%3C/svg%3E"),
        "#",
        "%23"
      );
      background-size: rem(12);
      & + label {
        opacity: 1;
      }
    }
  }
}

.form-switch {
  .form-check-input {
    //background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba%280,0,0,0.25%29'/></svg>") ;
    background-position: left center;
    background-size: contain;
    border-radius: rem(100);
    transition: background-position 0.15s ease-in-out;
    width: 3em;
    &:checked {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23fff'/></svg>");
      background-position: right center;
    }
  }
}

.bg-secondary {
  .form-control {
    background-color: transparent;
    border-bottom-color: #fff;
    color: #fff;
    &::placeholder {
      color: #fff;
      opacity: 0.5;
    }
  }
  [type="checkbox"] {
    &.form-check-input {
      background-color: transparent;
      border: rem(1) solid #fff;
    }
  }
  .form-switch {
    .form-check-input {
      background-color: transparent;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23fff'/></svg>");
      border-color: #fff;
      &:checked {
        background-color: $urbemar;
      }
    }
  }
}

// Inscripcion
input[type="date"]:invalid + label.placeholder {
  border-bottom: 0;
  top: 0;
  cursor: text;
}

input[type="date"]:focus + label.placeholder,
input[type="date"]:valid + label.placeholder {
  display: none;
}

.unity-base {
  display: none;
}

.masthead {
	position: relative;
	z-index: 3;
	&:before, &:after {
		content: "";
	}
	&__content {
		z-index: 1;
	}
	&__subtitle {
		line-height: (45/40);
	}
	&__nav {
		overflow: auto;
		a {
			color: #fff;
			border-bottom: rem(1) solid transparent;
			flex-shrink: 0;
			padding: rem(1) rem(6);
			&.active {
				border-bottom-color: #fff;
			}
			& + a {
				margin-left: rem(22);
			}
		}
	}
	@include media-breakpoint-up(lg) {
		&__nav {
			overflow: visible;
			a {
				padding: rem(1) rem(14);
				&.active {
					border-bottom-color: #fff;
				}
				& + a {
					margin-left: rem(32);
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		&__nav {
			a {
				& + a {
					margin-left: rem(48);
				}
			}
		}
	}
}

.masthead-100  {
	&:after {
		padding-bottom: calc(100vh - #{rem(76)});
	}
	@include media-breakpoint-up(lg) {
		&:after {
			padding-bottom: 100vh;
		}
	}
}

.masthead--full-shadow {
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}	
}

@include media-breakpoint-up(lg) {
	.masthead--full-shadow-lg {
		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}	
	}
}

.masthead--full-shadow-std:before { background-color: rgba($tertiary, .6) }
@include media-breakpoint-up(lg) {
	.masthead--full-shadow-std-lg:before { background-color: rgba($tertiary, .6) }
}
.masthead--full-shadow-home:before { background-color: rgba($tertiary, .45) }
.masthead--gradient {
	&:before {
		background: linear-gradient(0deg, rgba(0,29,48,1) 0%, rgba(0,29,48,0) 100%);
		height: rem(142);
		opacity: .75;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}	
	@include media-breakpoint-up(lg) {
		&:before {
			height: rem(364);
		}
	}
}

.masthead--promotions {
	&:after {
		padding-bottom: 100vh;
	}
  @include media-breakpoint-up(lg) {
    &:after {
      padding-bottom: ((71/160) * 100%)
    }
  }
}
@include media-breakpoint-up(lg) {
	.masthead--promotions-lg {
		&:after {
			padding-bottom: ((71/160) * 100%)
		}
	}
}

.masthead--legal {
	&:after {
		padding-bottom: ((176/390) * 100%)
	}
	@include media-breakpoint-up(lg) {
		&:after {
			padding-bottom: ((460/1920) * 100%)
		}
	}
}


.text-cms {
  + .btn-reset {
    margin-top: rem(48)
  }
}